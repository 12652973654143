<template>
  <div class="">
    <customer-form ref="form" action="Update" v-model="formData" @formSubmitted="updateCustomer" />
  </div>
</template>
  
<script>
import CustomerForm from "@/views/components/customer/CustomerForm.vue";
import CustomerService from "@/services/CustomerService";

export default {
  name: "UpdateOffer",
  components: {
    CustomerForm
  },
  data() {
    return {
      CustomerService: new CustomerService(),
      id: this.$route.params.id,
      formData: {
      },
    }
  },
  methods: {
    updateCustomer() {
      this.$refs.form.loading = true;
      this.CustomerService.update(this.formData.id, this.formData).then(res => {
        this.$router.push({ name: 'all-customers' })
      }).catch(err => {
      }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
    mappingData(data) {
      this.$refs.form.phone_number = data.phone_number;
      this.$refs.form.whatsapp_number = data.whatsapp_number;
      this.$refs.form.isSameNumber = data.phone_number == data.whatsapp_number;
      return {
        ...data,
        updating: true,
        gender: data.gender == "Male" ? 1 : 0,
      }
    },
    getCustomer() {
      this.CustomerService.show(this.id).then(res => {
        this.formData = this.mappingData(res);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getCustomer()
  }
}
</script>
  
<style scoped>

</style>
  